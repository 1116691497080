import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material-v6';
import { FormikErrors } from 'formik';
import { getKitchenShortCut } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';

interface KitchensDropdownProps {
  values: SyncFoodComponentReq;
  currentKitchen: Kitchen;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SyncFoodComponentReq>>;
}

const KitchensDropdown = ({ values, currentKitchen, setFieldValue }: KitchensDropdownProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();

  const kitchenOptions = userKitchens.filter((kitchen) => kitchen !== Kitchen.OM1 && kitchen !== currentKitchen);

  const handleKitchenChange = (event: SelectChangeEvent<string[]>) => {
    setFieldValue('kitchens', event.target.value as string[]);
  };

  const handleDelete = (kitchen: Kitchen) => {
    setFieldValue(
      'kitchens',
      values.kitchens.filter((k) => k !== kitchen)
    );
  };

  return (
    <FormControl fullWidth sx={{ marginTop: '32px' }}>
      <InputLabel id="multi-select-label">Select the kitchen to sync component with</InputLabel>
      <Select
        labelId="multi-select-label"
        multiple
        value={values.kitchens}
        onChange={handleKitchenChange}
        input={<OutlinedInput label="Select the kitchen to sync component with" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {(selected as string[]).map((value) => (
              <Chip
                key={value}
                label={getKitchenShortCut(value as Kitchen)}
                onDelete={() => handleDelete(value as Kitchen)}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </Box>
        )}
      >
        {kitchenOptions.map((kitchen) => (
          <MenuItem key={kitchen} value={kitchen}>
            {getKitchenShortCut(kitchen)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default KitchensDropdown;
