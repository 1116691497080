import { Country, Currency, Kitchen, RetailBranch } from '@calo/types';

export const getCountryFromBranch = (branch: RetailBranch) => {
  switch (branch) {
    case RetailBranch.BOXAT:
      return Country.SA;
    case RetailBranch.HITTEN:
      return Country.SA;
    case RetailBranch.ROSHAN:
      return Country.SA;
    case RetailBranch.SEDRA:
      return Country.SA;
    case RetailBranch.SEEF:
      return Country.BH;
    default:
      return Country.SA;
  }
};

export const getKitchenFromBranch = (branch: RetailBranch) => {
  switch (branch) {
    case RetailBranch.BOXAT:
      return Kitchen.SA1;
    case RetailBranch.HITTEN:
      return Kitchen.SA1;
    case RetailBranch.ROSHAN:
      return Kitchen.SA1;
    case RetailBranch.SEDRA:
      return Kitchen.SA1;
    case RetailBranch.SEEF:
      return Kitchen.BH1;
    default:
      return Kitchen.SA1;
  }
};

export const getCurrencyFromBranch = (branch: RetailBranch) => {
  switch (branch) {
    case RetailBranch.SEEF:
      return Currency.BHD;
    default:
      return Currency.SAR;
  }
};

export const getCurrencyFromKitchen = (kitchen: Kitchen) => {
  switch (kitchen) {
    case Kitchen.SA0:
    case Kitchen.SA1:
    case Kitchen.SA2:
      return Currency.SAR;
    case Kitchen.BH1:
    case Kitchen.BH0:
      return Currency.BHD;
    case Kitchen.AE0:
    case Kitchen.AE1:
      return Currency.AED;
    case Kitchen.KW0:
    case Kitchen.KW1:
      return Currency.KWD;
    case Kitchen.QA0:
    case Kitchen.QA1:
      return Currency.QAR;
    case Kitchen.OM0:
    case Kitchen.OM1:
      return Currency.OMR;
    case Kitchen.GB0:
    case Kitchen.GB1:
      return Currency.GBP;
    default:
      return Currency.SAR;
  }
};
