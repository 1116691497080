import { RefObject, useEffect, useRef, useState } from 'react';

import { getRecord, postRequest, updateFoodComponent, validateComponentFood } from 'actions';
import { ModalRef } from 'components';
import FoodComponentCalculatedMacrosModal from 'components/FoodComponentCalculatedMacrosModal/FoodComponentCalculatedMacrosModal';
import { FoodComponent, Ingredient, IngredientHeaderItem, PrototypeFoodComponent } from 'lib/interfaces';
import { isEqual, isNil, omit, sortBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { MeasurementUnit, Permission } from '@calo/dashboard-types';
import { Macros, Micronutrients } from '@calo/types';

import {
  ComponentHeaderCard,
  ComponentInformationCard,
  ComponentMethodCard,
  ComponentNameAndImageCard,
  CupsListCard,
  CustomSectionCard,
  IngredientListCard,
  MacrosMicrosInputCard
} from 'components/FoodComponentShared';
import { ChildComponentsCard } from 'components/FoodComponentShared/ExistingComponent';
import { FoodStatus, FormOperation, InputValueRestrictionType } from 'lib/enums';
import { cleanComponentIngredients, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import ComponentStandardsCard from './ComponentStandardsCard';
import SyncComponentPopup from './SyncComponentPopup';
import ValidateComponentPopup from './ValidateComponentPopup';
import { filterAndSortUsedOnMenu, isNewValidHeader } from './helpers';
import useFoodComponentForm from './useFoodComponentForm';
import { Box } from '@mui/material-v6';
import { styles } from './styles';

const ExactFoodComponent = () => {
  const roles = useUserRoles();
  const isDisabled = !roles.includes(Permission.UPDATE_FOOD_COMPONENTS);

  const { id } = useParams<{ id: string }>();
  const [showSyncComponentPopup, setShowSyncComponentPopup] = useState(false);
  const calculatedMacrosRef = useRef<ModalRef>();
  const confirmUpdateComponentRef = useRef<ModalRef>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [childComponents, setChildComponents] = useState<FoodComponent[]>([]);
  const [calculatedMacrosFromIngredients, setCalculatedMacrosFromIngredients] = useState<Macros | undefined>();
  const [calculatedMicronutrientsFromIngredients, setCalculatedMicronutrientsFromIngredients] = useState<
    Micronutrients | undefined
  >();
  const [isCalculatedMacrosDifferent, setIsCalculatedMacrosDifferent] = useState(false);
  const [structuredIngredients, setStructuredIngredients] = useState<IngredientHeaderItem[]>([]);
  const [isValidationFieldsChanged, setIsValidationFieldsChanged] = useState(false);

  const { data: foodComponent, isLoading } = useQuery<any, Error, FoodComponent>(['food-components', id], getRecord, {
    suspense: true,
    keepPreviousData: false
  });

  const { data, isLoading: loadingFood } = useQuery<any, Error, any>(
    [
      'food/list',
      {
        filters: {
          brand: foodComponent?.brand,
          country: foodComponent?.country,
          kitchen: foodComponent?.kitchen,
          componentId: foodComponent?.id,
          isDeleted: false,
          status: FoodStatus.approved
        },
        page: 0,
        limit: 50
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!foodComponent && roles.includes(Permission.DELETE_INGREDIENT)
    }
  );

  const { mutateAsync: updateMutation, isSuccess: isUpdateSuccess } = useMutation(updateFoodComponent);

  const handleUpdateFood = (values: Partial<FoodComponent>, structuredIngredients: IngredientHeaderItem[]) => {
    const updatedIngredients: Ingredient[] = [];
    let tempHeaderName: string | null | undefined = null;
    for (const structuredIngredient of structuredIngredients) {
      if (structuredIngredient.type === 'header') {
        tempHeaderName = structuredIngredient.header;
      } else {
        updatedIngredients.push({ ...structuredIngredient.ingredient, header: tempHeaderName ?? undefined });
      }
    }

    const cleanedIngredients = cleanComponentIngredients(updatedIngredients);

    const { purchasingCost: _purchasingCost, prototype: _prototype, parentComponentIds: _, ...rest } = values;
    return updateMutation({ id, ...rest, ingredients: cleanedIngredients as any });
  };

  const submitValues = async (values: Partial<FoodComponent>) => {
    await handleUpdateFood(values, structuredIngredients);
  };

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, setFieldValue, setValues, errors } =
    useFoodComponentForm(foodComponent, submitValues);

  let currentHeader: string | null | undefined;
  useEffect(() => {
    const sortedIngredients = sortBy(values.ingredients, [(ingredient) => (isNil(ingredient.header) ? -1 : 0), 'header']);

    const updatedStructuredIngredients: IngredientHeaderItem[] = [];
    for (const ingredient of sortedIngredients) {
      if (isNewValidHeader(ingredient.header, currentHeader)) {
        currentHeader = ingredient.header;
        updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
      }
      updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
    }

    setStructuredIngredients(updatedStructuredIngredients);
  }, [values.ingredients]);

  useEffect(() => {
    if (!foodComponent) {
      return;
    }
    setChildComponents([...(foodComponent.childComponents as unknown as FoodComponent[])]);
    setValues({
      ...(omit(foodComponent, ['id', 'createdAt', 'updatedAt', 'usedOnMenu', 'lastUsedOnMenu']) as FoodComponent),
      micronutrients: {
        addedSugar: foodComponent?.micronutrients?.addedSugar || 0,
        cholesterol: foodComponent?.micronutrients?.cholesterol || 0,
        saturatedFats: foodComponent?.micronutrients?.saturatedFats || 0,
        sodium: foodComponent?.micronutrients?.sodium || 0,
        totalSugar: foodComponent?.micronutrients?.totalSugar || 0,
        transFats: foodComponent?.micronutrients?.transFats || 0
      },
      childComponents: (foodComponent.childComponents ?? []).map((child) => ({ id: child.id, quantity: child.quantity }))
    });
  }, [foodComponent]);

  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  const filteredFoodUsed = filterAndSortUsedOnMenu(foodComponent);
  const handleSubmitSellingPrice = (value: number) => {
    setFieldValue('sellingPrice', value);
    setIsEdit(false);
  };

  const {
    data: differences,
    mutate: validateComponent,
    isSuccess,
    isLoading: isValidateComponentLoading
  } = useMutation(validateComponentFood);

  useEffect(() => {
    if (isSuccess) {
      confirmUpdateComponentRef.current?.open();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      confirmUpdateComponentRef.current?.close();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    const isDifferent = findValidationDifferences();
    if (isDifferent) {
      setIsValidationFieldsChanged(true);
    }
  }, [values]);

  const findValidationDifferences = () => {
    if (
      foodComponent?.cookedRawFactor !== Number(values.cookedRawFactor) ||
      foodComponent?.weight !== Number(values.weight) ||
      !isEqual(foodComponent?.macros, values.macros) ||
      !isEqual(foodComponent?.micronutrients, values.micronutrients)
    ) {
      return true;
    }
    return false;
  };
  const sectionRefs: RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  return (
    <>
      <ComponentHeaderCard
        operation={FormOperation.update}
        foodComponent={foodComponent as PrototypeFoodComponent | undefined}
        filteredFoodUsed={filteredFoodUsed}
        setShowSyncComponentPopup={setShowSyncComponentPopup}
        isValid={isValid}
        isSubmitting={isValidateComponentLoading || isSubmitting}
        isEdit={isEdit}
        componentFoodList={data?.data ?? []}
        isFoodLoading={loadingFood}
        handleSubmit={() => {
          if (isValidationFieldsChanged) {
            validateComponent({ ...values, id: foodComponent?.id } as any);
          } else {
            handleSubmit();
          }
        }}
        isDisabled={isDisabled}
        sectionRefs={sectionRefs}
      />
      {foodComponent && (
        <Box sx={styles.form}>
          <ComponentNameAndImageCard
            foodComponent={foodComponent}
            values={values}
            errors={errors}
            isLoading={isLoading}
            handleChange={handleChange}
            isDisabled={isDisabled}
            ref={sectionRefs[0]}
          />
          <IngredientListCard
            values={values}
            childComponents={childComponents}
            structuredIngredients={structuredIngredients}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setStructuredIngredients={setStructuredIngredients}
            ref={sectionRefs[1]}
          />
          <ChildComponentsCard
            values={values}
            foodComponent={foodComponent}
            childComponents={childComponents}
            setChildComponents={setChildComponents}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setIsCalculatedMacrosDifferent={setIsCalculatedMacrosDifferent}
            setCalculatedMicronutrientsFromIngredients={setCalculatedMicronutrientsFromIngredients}
            setCalculatedMacrosFromIngredients={setCalculatedMacrosFromIngredients}
            isDisabled={isDisabled}
            ref={sectionRefs[2]}
          />
          <MacrosMicrosInputCard
            operation={FormOperation.update}
            values={values}
            errors={errors}
            setValues={setValues}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            calculatedMacrosRef={calculatedMacrosRef}
            isCalculatedMacrosDifferent={isCalculatedMacrosDifferent}
            calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
            calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
            isDisabled={isDisabled}
            ref={sectionRefs[3]}
          />
          <ComponentInformationCard
            values={values}
            errors={errors}
            childComponents={childComponents}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            isDisabled={isDisabled}
            handleSubmitSellingPrice={handleSubmitSellingPrice}
            ref={sectionRefs[4]}
          />
          <CustomSectionCard
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            isDisabled={isDisabled}
            ref={sectionRefs[5]}
          />
          {values.measurementUnit === MeasurementUnit.cup && (
            <CupsListCard
              values={values}
              isDisabled={isDisabled}
              operation={FormOperation.create}
              setValues={setValues}
              setFieldValue={setFieldValue}
            />
          )}
          <ComponentMethodCard
            operation={FormOperation.update}
            values={values}
            errors={errors}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setValues={setValues}
            setFieldValue={setFieldValue}
            isDisabled={isDisabled}
            ref={sectionRefs[6]}
          />
          <ComponentStandardsCard values={values} setValues={setValues} isDisabled={isDisabled} ref={sectionRefs[7]} />
        </Box>
      )}
      <FoodComponentCalculatedMacrosModal
        calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
        calculatedMacrosRef={calculatedMacrosRef}
        calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
      />
      {foodComponent && (
        <SyncComponentPopup
          id={id}
          componentName={foodComponent.name.en}
          currentKitchen={foodComponent.kitchen}
          closePopup={() => setShowSyncComponentPopup(false)}
          showSyncComponentPopup={showSyncComponentPopup}
        />
      )}
      <ValidateComponentPopup
        confirmUpdateComponentRef={confirmUpdateComponentRef}
        foodDifferences={differences ?? []}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default ExactFoodComponent;
