import { ComponentStandards } from 'components';
import { FormikErrors } from 'formik';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { Card } from '@mui/material-v6';
import { styles } from './styles';
import { forwardRef } from 'react';

interface ComponentMethodCardProps {
  values: Omit<FoodComponent, BaseOmit>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  isDisabled?: boolean;
}

const ComponentStandardsCard = forwardRef<HTMLDivElement, ComponentMethodCardProps>(
  ({ values, isDisabled, setValues }: ComponentMethodCardProps, ref) => {
    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <ComponentStandards
          values={values}
          setValues={(v) => setValues(v)}
          isDisabled={Boolean(isDisabled)}
          allowUploadImages={false}
        />
      </Card>
    );
  }
);

export default ComponentStandardsCard;
